import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window;

  return (
    <div className="container vh-100 d-flex flex-column justify-content-center align-items-center">
    {browser && (
      <>
        <h1 style={{fontSize:"8rem"}}>404</h1>
        <h2 className="text-primary">Hoppla! Seite nicht gefunden</h2>
        <h5 className="text-secondary">We're sorry, but the page you were looking for doesn't exist.</h5>
        <a href="/" type="button" className="btn btn-primary mt-4">Zur Startseite</a>
      </>
    )}
    </div>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)
